import React, { ReactElement } from "react";
import "./Contact.scss";
import image from "./9.jpg";
import PageHeader from "../../components/PageHeader";
import PageFooter from "../../components/PageFooter";

const className = "contact";

const style: React.CSSProperties = {
    backgroundImage: `url(${image})`
};

const date = new Date();

const Contact = (): ReactElement => (
    <div className={`section ${className} scroll-${className}`} style={style}>
        <PageHeader text="Kontakta mig" />
        <div className={`${className}__box`}>
            <h2>Skicka ett mail eller så</h2>
            <p>
                Vill du anlita mig för fotografi, webbutveckling eller något
                helt annat? Tveka inte att skicka ett mail till{" "}
                <a href="mailto:kontakt@jonasahnoff.se">
                    kontakt@jonasahnoff.se
                </a>{" "}
                eller leta upp mig på{" "}
                <a
                    href="https://www.linkedin.com/in/jonas-ahnoff-2216a763/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    LinkedIn
                </a>
                .
            </p>
            <p>
                Jag fakturerar via{" "}
                <a
                    href="https://coolcompany.com/se/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Cool Company
                </a>{" "}
                och kan därmed anlitas för diverse jobb som annars skulle kräva
                anställning eller egen F-skatt.
            </p>
        </div>
        <PageFooter
            text={`&copy; Jonas Ahnoff ${date.toISOString().substr(0, 10)}`}
        />
    </div>
);

export default Contact;
