import React, { ReactElement } from "react";
import ButtonNext from "../../components/ButtonNext";
import "./Spacer.scss";

interface SpacerProps {
    image: string;
    name: string;
    next?: string;
}

const Spacer = ({ image, name, next }: SpacerProps): ReactElement => {
    const style = {
        backgroundImage: `url(${image})`
    };
    return (
        <div className={`section spacer scroll-${name}`} style={style}>
            {next && <ButtonNext target={next} />}
        </div>
    );
};

export default Spacer;
