import React, { Fragment, ReactElement } from "react";

import Splash from "../pages/Splash";
import Spacer from "../pages/Spacer";
// import Photo from "../pages/Photo";
import Contact from "../pages/Contact";
// import Webdev from "../pages/Webdev";

import imageFika from "./images/1.jpg";
import imageLibrary from "./images/4.jpg";
import imageVuvuzela from "./images/8.jpg";

const Page = (): ReactElement => (
    <Fragment>
        <Splash />
        <Spacer image={imageFika} name="spacer-1" next="spacer-2" />
        {/* <Photo /> */}
        <Spacer image={imageLibrary} name="spacer-2" next="spacer-3" />
        {/* <Webdev /> */}
        <Spacer image={imageVuvuzela} name="spacer-3" next="contact" />
        <Contact />
    </Fragment>
);

export default Page;
