import React, { ReactElement } from "react";
import "./PageHeader.scss";

interface PageHeaderProps {
    text: string;
}

const className = "page-header";

const PageHeader = ({ text }: PageHeaderProps): ReactElement => (
    <div className={className} dangerouslySetInnerHTML={{ __html: text }} />
);

export default PageHeader;
