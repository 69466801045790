import React, { ReactElement } from "react";
import image from "./me.jpg";
import "./Card.scss";

const className = "card";

const Card = (): ReactElement => (
    <div className={className}>
        <div className={`${className}__image`}>
            <img src={image} alt="Jonas Ahnoff" />
        </div>
        <div className={`${className}__info`}>
            <h1>{"Jonas Ahnoff"}</h1>
            <ul>
                <li>Amatörfotograf</li>
                <li>Friluftstok</li>
                <li>Webbutvecklare</li>
            </ul>

            <a href="mailto:kontakt@jonasahnoff.se">
                {"kontakt [at] jonasahnoff.se"}
            </a>
        </div>
    </div>
);

export default Card;
