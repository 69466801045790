import React, { ReactElement } from "react";
import "./ButtonNext.scss";

interface ButtonNextProps {
    target: string;
}

const className = "button-next";

const ButtonNext = ({ target }: ButtonNextProps): ReactElement => (
    <button
        className={className}
        onClick={(): void => {
            const targetEl = document.querySelector<HTMLDivElement>(
                `.scroll-${target}`
            );

            if (targetEl) {
                const rootEl = document.querySelector("#root");
                if (rootEl) {
                    rootEl.scroll({
                        top: targetEl.offsetTop,
                        behavior: "smooth"
                    });
                }
            }
        }}
        aria-label="Nästa sida"
    >
        <div className={`${className}__arrow`} />
    </button>
);

export default ButtonNext;
