import React, { ReactElement } from "react";
import PageHeader from "../../components/PageHeader";
import Card from "../../components/Card";
import ButtonNext from "../../components/ButtonNext";
import "./Splash.scss";

const Splash = (): ReactElement => (
    <div className="section splash scroll-splash">
        <PageHeader text="Jonas<span>Ahnoff</span>.se" />
        <Card />
        <ButtonNext target="spacer-1" />
    </div>
);

export default Splash;
