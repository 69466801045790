import React, { ReactElement } from "react";
import HttpsRedirect from "react-https-redirect";
import Page from "./containers/Page";
import "./style/_global.scss";

const App = (): ReactElement => (
    <HttpsRedirect>
        <Page />
    </HttpsRedirect>
);

export default App;
