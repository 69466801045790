import React, { ReactElement } from "react";
import "./PageFooter.scss";

interface PageFooterProps {
    text: string;
}

const className = "page-footer";

const PageFooter = ({ text }: PageFooterProps): ReactElement => (
    <div className={className} dangerouslySetInnerHTML={{ __html: text }} />
);

export default PageFooter;
